import React, { useEffect, useState } from "react"
import { graphql as gitGraph } from "@octokit/graphql"

const gitWithAuth = gitGraph.defaults({
  headers: {
    authorization: `token e1455abcccc8d2558de80c9f29deb8047d3ef570`,
  },
});

const Projects = () => {

  const [repos, setRespos] = useState([]);

  useEffect(() => {
    (async () => {
      const { viewer } = await gitWithAuth(`{
      viewer { 
        repositories(last: 6, privacy: PUBLIC) {
          nodes {
            name
            openGraphImageUrl
            description
            url
              languages(first: 4) {
                nodes {
                  name
                  color
                }
              }
            }
        }
      }
    }`);
      setRespos(viewer.repositories.nodes.reverse());
    })();
  },[]);

  return (
    <section id="projects" className="section">
      <div className="container">
        <div className="section-header" data-sal="slide-up">
          <h2 className="section-title">Projects</h2>
          <div className="section-line"></div>
        </div>
        <div className="row">
          {
            repos.map( ( repo, index ) => (
              <div className="col-md-4" key={ index }>
                <a href={repo.url} target="_blank" rel="noreferrer" className="project-card">
                  <div className="project-img">
                    <img src={repo.openGraphImageUrl} alt={repo.name}/>
                  </div>
                  <div className="project-desc">
                    <h3>{repo.name}</h3>
                    <p>{repo.description}</p>
                    <div className="project-desc-tags">
                      {
                        repo.languages.nodes.map( (lang, index) => (
                          <span className="tags" key={index}>
                            <span className="lang-dot" style={{background: lang.color}}></span>
                            {lang.name}</span>
                        ))
                      }
                    </div>
                  </div>
                </a>
              </div>
            ))
          }
          <div className="col-md-12">
            <a className="btn-view-more" href="https://github.com/AbhayChatterjee?tab=repositories" target="_blank" rel="noreferrer" data-sal="slide-up">VIEW MORE</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects
