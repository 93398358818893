import React from "react"
import { connect } from "react-redux"

const SkillsBase = ({theme}) => {
  return (
    <section id="skills" className="section section-alt">
      <div className="container">
        <div className="section-header" data-sal="slide-up">
          <h2 className="section-title">Skills</h2>
          <div className="section-line"></div>
        </div>
        <div className="row">
          <div className="col-4 col-lg-2 ">
            <div className="logo-box" data-sal="slide-up">
              <div>
                <img src={require('../../static/images/skills/01.png')} className="img-fluid" alt="java"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="100">
              <div>
                <img src={require('../../static/images/skills/02.png')} className="img-fluid" alt="node.js"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="200">
              <div>
                <img src={require('../../static/images/skills/03.png')} className="img-fluid" alt="python"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="100">
              <div>
                <img src={require('../../static/images/skills/04.png')} className="img-fluid" alt="golang"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="200">
              <div>
                <img src={require('../../static/images/skills/05.png')} className="img-fluid" alt="typescript"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require(`../../static/images/skills/06-${theme}.png`)} className="img-fluid" alt="aws"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/07.png')} className="img-fluid" alt="spring"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require(`../../static/images/skills/08-${theme}.png`)} className="img-fluid" alt="express"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require(`../../static/images/skills/09-${theme}.png`)} className="img-fluid" alt="flask"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/10.png')} className="img-fluid" alt="docker"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/11.png')} className="img-fluid" alt="angular"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require(`../../static/images/skills/12-${theme}.png`)} className="img-fluid" alt="serverless"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/13.png')} className="img-fluid" alt="mysql"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/14.png')} className="img-fluid" alt="mongo"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/15.png')} className="img-fluid" alt="gcp"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/16.png')} className="img-fluid" alt="graphql"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require('../../static/images/skills/17.png')} className="img-fluid" alt="react"/>
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2">
            <div className="logo-box" data-sal="slide-up" data-sal-delay="300">
              <div>
                <img src={require(`../../static/images/skills/18-${theme}.png`)} className="img-fluid" alt="elastic"/>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="small text-muted text-center">All logos and trademarks belong to their respective owners.<br/>
              No copyright infringement intended.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ theme }) => {
  return { theme }
}

const Skills = connect(mapStateToProps)(SkillsBase)

export default Skills
