import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Skills from "../components/skills"
import Contact from "../components/contact"
import About from "../components/about"
import Projects from "../components/projects"
import firebase from "gatsby-plugin-firebase"

const Index = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata.title
  // const posts = data.allMarkdownRemark.edges

  useEffect(() => {
    firebase
      .analytics()
      .logEvent("visited_home_page")
  }, [])

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <About/>
      <Skills/>
      <Projects/>
      {/*<RecentBlogs moduleGray={true}/>*/}
      <Contact/>
    </Layout>
  )
}

export default Index

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       edges {
//         node {
//           excerpt
//           fields {
//             slug
//           }
//           frontmatter {
//             date(formatString: "MMMM DD, YYYY")
//             title
//             description
//           }
//         }
//       }
//     }
//   }
// `
