import React from "react"
import {Navbar, Nav, Container} from "react-bootstrap"
import { connect } from "react-redux"
import Scrollspy from 'react-scrollspy'

const LayoutBase = ({ location, title, children, theme, toggleTheme }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let year = new Date().getFullYear();

  const isRoot = location.pathname === rootPath;

  const header = (
    <Navbar collapseOnSelect className="navbar-custom" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand className="mr-auto" href={`${isRoot?'':rootPath}#about`}>
          <img src={require('../../static/images/logo.png')} className="img-fluid" alt="logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" as="a" children={<span className="ac-menu" />}/>
        <Navbar.Collapse id="responsive-navbar-nav">
          {/*ml-auto mt-2 mt-lg-0 navbar-nav*/}
          <Scrollspy className="ml-auto mt-2 mt-lg-0 navbar-nav"
                     items={ ['about', 'skills', 'projects', 'contact'] }
                     currentClassName="active">
            <Nav.Item as="li" className="mb-0">
              <Nav.Link href={`${isRoot?'':rootPath}#about`} >HelloWorld.java</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mb-0">
              <Nav.Link href={`${isRoot?'':rootPath}#skills`}>Skills.js</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mb-0">
              <Nav.Link href={`${isRoot?'':rootPath}#projects`}>Projects.py</Nav.Link>
            </Nav.Item>
            {/*<Nav.Item as="li" className="mb-0">*/}
            {/*  <Nav.Link href="#blog">Blog.ts</Nav.Link>*/}
            {/*</Nav.Item>*/}
            <Nav.Item as="li" className="mb-0">
              <Nav.Link href={`${isRoot?'':rootPath}#contact`}>GetInTouch.go</Nav.Link>
            </Nav.Item>
          </Scrollspy>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

  let footer = (
    <Navbar id="footer" fixed="bottom">
      <Container fluid>
        <Nav className="" as="ul">
          <Nav.Item as="li" className="mb-0">
            <a onClick={() => toggleTheme()}>
            {
              theme === 'light' ?
                <span className="ac-moon"/>
                :
                <span className="ac-sun"/>
            }
            </a>
          </Nav.Item>
          <Nav.Item as="li" className="mb-0">
            <a href="https://linkedin.com/in/abhaychatterjee" target="_blank" rel="noreferrer" >
                <span className="ac-linkedin"/>
            </a>
          </Nav.Item>
          <Nav.Item as="li" className="mb-0">
            <a href="https://github.com/abhaychatterjee" target="_blank" rel="noreferrer" >
              <span className="ac-github"/>
            </a>
          </Nav.Item>
        </Nav>
        <p className="copyright">
          Abhay Chatterjee | { year }
        </p>
      </Container>
    </Navbar>
  )

  return (
    <div className={theme}>
      {header}
      {children}
      {footer}
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  return { theme }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTheme: () => dispatch({ type: `TOGGLE_THEME`})
  }
}

const Layout = connect(mapStateToProps, mapDispatchToProps)(LayoutBase)

export default Layout
