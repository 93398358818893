import { createStore } from "redux"

/*
*   using react-redux with gatsby
*   ref: https://github.com/gatsbyjs/gatsby/tree/ec8deb2d757524316a06ae3cefe26d7626549ad6/examples/using-redux
*
*   when using localstorage with ssr always check if (typeof window !== 'undefined')
*   ref: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
*/

const reducer = (state, action) => {
  if (action.type === `TOGGLE_THEME`) {
    // if (typeof window !== 'undefined')
      // localStorage.setItem('theme', state.theme === 'dark'? 'light' : 'dark');

    return Object.assign({}, state, {
      theme: state.theme === 'dark'? 'light' : 'dark',
    })
  }
  return state;
}

let theme = 'dark';
// if (typeof window !== 'undefined')
//   if (localStorage.getItem('theme'))
//     theme = localStorage.getItem('theme');

const initialState = { theme }

const store = createStore(reducer, initialState);
export default store;