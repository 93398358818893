import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    firebase
      .analytics()
      .logEvent("visited_404_page")
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <section id="home" className="section section-fit-height">
        <div className="container">
          <div className="section-header" data-sal="slide-up" style={{paddingTop: '25vh'}}>
            <h2 className="section-title">Not Found</h2>
            <p className="section-subtitle">You just hit a route that doesn&#39;t exist...</p>
            <Link to="/" className="btn-cta">Return Home</Link>
            <div className="section-line"></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
