import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"

const AboutBase = ({theme}) => {
  return (
    <section id="about" className="section">
      <Container>
        <div className="row position-relative margin-0">
          {/*<div className="col-xs-12 col-md-5 side-image">*/}
          {/*  <div className="side-image-wrapper">*/}
          {/*    <img src={require(`../../static/images/about-${theme}.png`)} alt="about-banner"/>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="col-xs-12 col-md-7 side-image-text">
            <div className="row">
              <div className="col-sm-12">
                <div className="section-header wow fadeInUp">
                  <h2 className="about-title"><span style={{color: '#C87630'}}>Hi!</span> <span style={{color: '#9876AA'}}>I am</span> Abhay</h2>
                  <h3 className="about-subtitle">I build softwares for the cloud.</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="about-desc">
                  I'm a Software Engineer specializing in building and designing exceptional cloud native applications, web applications, and everything in between..
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

const mapStateToProps = ({ theme }) => {
  return { theme }
}

const About = connect(mapStateToProps)(AboutBase)

export default About
